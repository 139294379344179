<template>
    <loader
        :isLoading="isLoading"
        :error="error"
        errorMessage="members.showError"
    >
        <h1 class="text-center mb-4">{{ $t("members.update.title") }}</h1>
        <v-card outlined color="primary" class="mt-2">
            <v-card-text>
                <v-form ref="form" @submit.prevent="onUpdate">
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="member.firstname"
                                :label="$t('members.member.firstName') + '*'"
                                :rules="requiredRule"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="member.lastname"
                                :label="$t('members.member.lastName') + '*'"
                                :rules="requiredRule"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-text-field
                        v-model="member.birthdate"
                        :label="$t('members.member.birthDate')"
                        type="date"
                    ></v-text-field>
                    <v-row>
                        <v-col cols="12" sm="6" class="py-0">
                            <v-text-field
                                v-model="member.address.street"
                                :label="$t('members.member.address.street')"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" class="py-0">
                            <v-text-field
                                v-model="member.address.street_2"
                                :label="$t('members.member.address.street_2')"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="2" class="py-0">
                            <v-text-field
                                v-model="member.address.post_code"
                                :label="$t('members.member.address.postCode')"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" class="py-0">
                            <v-text-field
                                v-model="member.address.city"
                                :label="$t('members.member.address.city')"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-text-field
                        v-model="member.phone"
                        :label="$t('members.member.phone')"
                        :rules="[phoneRule.phone(member.phone)]"
                    ></v-text-field>
                    <v-text-field
                        v-model="member.email"
                        :label="$t('members.member.email')"
                        :rules="[emailRule.email(member.email)]"
                    ></v-text-field>
                    <v-row class="mb-2" justify="center">
                        <v-col cols="auto">
                            <v-btn color="primary" type="submit">
                                {{ $t("button.update") }}
                            </v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn
                                :to="{
                                    name: 'Member',
                                    params: {
                                        memberId: memberId,
                                    },
                                }"
                            >
                                {{ $t("button.return") }}
                            </v-btn>
                        </v-col>
                    </v-row>
                    <p v-if="formIsInvalid" class="text-center warning--text">
                        {{ $t("form.error") }}
                    </p>
                    <p v-if="updateError" class="text-center error--text">
                        {{ $t("members.update.error") }}
                    </p>
                </v-form>
            </v-card-text>
        </v-card>
    </loader>
</template>

<script>
import { mapState } from "vuex";
import Loader from "@/components/utils/Loader";

export default {
    components: {
        Loader,
    },

    props: {
        memberId: {
            type: [String, Number],
            required: true,
        },
    },

    data() {
        return {
            member: {
                address: {},
            },

            isLoading: true,
            error: false,

            formIsInvalid: false,

            isLoadingUpdate: false,
            updateError: false,
        };
    },

    mounted() {
        this.getMember();
    },

    computed: {
        ...mapState({
            requiredRule: (state) => state.utils.requiredRule,
            emailRule: (state) => state.utils.emailRule,
            phoneRule: (state) => state.utils.phoneRule,
        }),
    },

    methods: {
        getMember() {
            this.isLoading = true;
            this.error = false;

            this.$store
                .dispatch("ohme/getMember", this.memberId)
                .then((data) => {
                    if (!data.data.canUpdate) {
                        this.$router.push({ name: "403" });
                    } else {
                        this.member = data.data.member;
                        if (!this.member.address) {
                            this.member.address = {};
                        }
                        this.isLoading = false;
                    }
                })
                .catch((err) => {
                    if (
                        err !== undefined &&
                        err.response !== undefined &&
                        err.response.status == 403
                    ) {
                        this.$router.push({ name: "403" });
                    } else {
                        this.isLoading = false;
                        this.error = true;
                    }
                });
        },

        onUpdate() {
            if (this.$refs.form.validate()) {
                this.isLoadingUpdate = true;
                this.updateError = false;

                this.$store
                    .dispatch("ohme/updateMember", {
                        memberId: this.memberId,
                        form: this.member,
                    })
                    .then(() => {
                        this.$router.push({
                            name: "Member",
                            params: {
                                memberId: this.memberId,
                            },
                        });
                    })
                    .catch(() => {
                        this.isLoadingUpdate = false;
                        this.updateError = true;
                    });
            } else {
                this.formIsInvalid = true;
            }
        },
    },
};
</script>
